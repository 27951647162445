import { Component, OnInit } from '@angular/core';
import { MuralsService } from '../../services/murals.service';

@Component({
  selector: 'app-mural',
  templateUrl: './mural.component.html',
  styleUrls: ['./mural.component.css']
})
export class MuralComponent implements OnInit {
  murals: any = [];
  constructor(
    private muralService: MuralsService
  ) { }

  ngOnInit(): void {
    this.showMurals();
  }

  showMurals() {
    this.muralService.getMurals().pipe().subscribe(murals => {
      this.murals = murals;
    });
  }
}
