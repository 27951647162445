<div class="container">
    <h1 class="font-Museo text-2xl md:text-4xl px-1 text-center font-medium text-gray-800 dark:text-gray-100 my-4">En el Umbral de lo Desconocido - 2020</h1>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 md:gap-4 mx-2 md:mx-0">
        <div class="" *ngFor="let paint of paints" >
            <img (click)="openModal(paint.image, paint.name)" class="rounded-md object-cover md:h-96 w-full md:transform duration-700 hover:scale-105 hover:shadow-2xl cursor-pointer" *ngIf="paint.isVisible" [src]="paint.image ? paint.image : 'assets/header.jpg' " [alt]="paint.name">
        </div>
    </div>
</div>

<!-- modal -->
<div id="modal" class="modal-container">
    <h2 id="nameImg" style="display: none;">{{name}}</h2>
    <img id="modalImg" style="display: none;" [src]="paint">
</div>

