import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//Firebase imports
import { firebaseConfig } from '../environments/firebaseconfig';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { NavComponent } from './components/nav/nav.component';
import { WrapperComponent } from './components/wrapper/wrapper.component';
import { PaintsComponent } from './components/paints/paints.component';
import { FooterComponent } from './components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MuralComponent } from './components/mural/mural.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    WrapperComponent,
    PaintsComponent,
    FooterComponent,
    MuralComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
