import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaintsService {

  constructor(
    private Firestore: AngularFirestore
  ) { }

  database = this.Firestore.collection('pinturas');

  getPaints() {
    return this.database.snapshotChanges().pipe(map( paints => {
      return paints.map(paint => {
        const data = paint.payload.doc.data();
        const id = paint.payload.doc.id;
        return {id, ...(data as object) };
      });
     }));
  }
}
