import { Component, OnInit, ViewChild } from '@angular/core';
import { PaintsService } from '../../services/paints.service';

@Component({
  selector: 'app-paints',
  templateUrl: './paints.component.html',
  styleUrls: ['./paints.component.css']
})
export class PaintsComponent implements OnInit {
  paints: any = [];
  paint: string;
  name: string;
  constructor(private paintsService: PaintsService) { }

  ngOnInit(): void {
    this.showPaints(); 
  }

  showPaints() {
    this.paintsService.getPaints().subscribe(paints => {
      this.paints = paints;
    });
  }

  openModal(paint, name) {
    const modal = document.getElementById('modal');
    const modalImg = document.getElementById('modalImg');
    const nameImg = document.getElementById('nameImg');
    modal.classList.add('active');
    modalImg.setAttribute('style','display:flex');
    nameImg.setAttribute('style','display:flex');
    modal.addEventListener('click',()=> {
      modal.classList.remove('active');
      modalImg.setAttribute('style','display:none');
      nameImg.setAttribute('style','display:none')
    })
    this.paint = paint;
    this.name = name;
  }


	
}
