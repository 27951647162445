<div id="wrapper" class="relative flex items-center justify-center">
    <div class="absolute ">
        <div class="text-center">
            <h2 class="font-Museo font-bold text-gray-200 md:text-6xl text-4xl shadow">Alberto Petriz</h2>
            <span class="font-Museo font-bold text-gray-200 md:text-3xl text-1xl shadow">Obra Plástica</span>
        </div>
    </div>
    <img class="max-h-screen object-cover w-full" src="assets/Shunca.jpg" alt="">
</div>


