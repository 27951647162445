import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MuralsService {

  constructor(
    private Firestore: AngularFirestore
  ) { }

  database = this.Firestore.collection('murales');

  getMurals() {
    return this.database.snapshotChanges().pipe(map( murals => {
      return murals.map(mural => {
        const data = mural.payload.doc.data();
        const id = mural.payload.doc.id;
        return {id, ...(data as object) };
      });
     }));
  }
}
